<template>
  <div class="affixion-contain">
    <Form :label-width="115" :model="uricData">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="尿酸值：">
            <Row>
              <Col :span="21"><Input v-model="uricData.value" placeholder="请输入尿酸值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave()">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			uricData: {
				test_date: '',
				test_time: '',
				value: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.uricData.member_id = this.memberId;
				MemberService.uricacidAdd(this.uricData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.uricData.test_date = value;
		},
		handleChangeTime(value) {
			this.uricData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.uricData.test_date) {
				this.$Message.warning('请输入日期');
				return false;
			} else if (!this.uricData.test_time) {
				this.$Message.warning('请输入采集时间');
				return false;
			} else if (this.uricData.value === '') {
				this.$Message.warning('请输入尿酸值');
				return false;
			} else if (!this.isPositiveNumber(this.uricData.value)) {
				this.$Message.warning('请输入尿酸有效值');
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
